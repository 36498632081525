import { imagesPhilly } from '../../../components/US/NorthEast/imagesPhilly'
import ImageModal from '../../../components/ImageModal'

function Philly() {
  return (
    <>
      {!imagesPhilly && <div>Loading...</div>}
      {imagesPhilly && 
        <div className="images">
          <ImageModal src={imagesPhilly.chimney1} alt="chimney-1" height="200"/>
          <ImageModal src={imagesPhilly.corner1} alt="corner-1" height="200"/>
          <ImageModal src={imagesPhilly.drexel1} alt="drexel-1" height="200"/>
          <ImageModal src={imagesPhilly.fireescape1} alt="fireescape-1" height="200"/>
          <ImageModal src={imagesPhilly.fmc1} alt="fmc-1" height="200"/>
          <ImageModal src={imagesPhilly.fmc2} alt="fmc-2" height="200"/>
          <ImageModal src={imagesPhilly.mural1} alt="mural-1" height="200"/>
          <ImageModal src={imagesPhilly.mural2} alt="mural-2" height="200"/>
          <ImageModal src={imagesPhilly.mural3} alt="mural-3" height="200"/>
          <ImageModal src={imagesPhilly.mural4} alt="mural-4" height="200"/>
          <ImageModal src={imagesPhilly.mural5} alt="mural-5" height="200"/>
          <ImageModal src={imagesPhilly.mural6} alt="mural-6" height="200"/>
          <ImageModal src={imagesPhilly.mural7} alt="mural-7" height="200"/>
          <ImageModal src={imagesPhilly.mural8} alt="mural-8" height="200"/>
          <ImageModal src={imagesPhilly.mural9} alt="mural-9" height="200"/>
          <ImageModal src={imagesPhilly.mural10} alt="mural-10" height="200"/>
          <ImageModal src={imagesPhilly.mural11} alt="mural-11" height="200"/>
          <ImageModal src={imagesPhilly.mural12} alt="mural-12" height="200"/>
          <ImageModal src={imagesPhilly.mural13} alt="mural-13" height="200"/>
          <ImageModal src={imagesPhilly.mural14} alt="mural-14" height="200"/>
          <ImageModal src={imagesPhilly.mural15} alt="mural-15" height="200"/>
          <ImageModal src={imagesPhilly.muraltower1} alt="muraltower-1" height="200"/>
          <ImageModal src={imagesPhilly.muraltower2} alt="muraltower-2" height="200"/>
          <ImageModal src={imagesPhilly.noparking1} alt="noparking-1" height="200"/>
          <ImageModal src={imagesPhilly.phillyskyline1} alt="phillyskyline-1" height="200"/>
          <ImageModal src={imagesPhilly.phillyskyline2} alt="phillyskyline-2" height="200"/>
          <ImageModal src={imagesPhilly.postoffice1} alt="postoffice-1" height="200"/>
          <ImageModal src={imagesPhilly.railcars1} alt="railcars-1" height="200"/>
          <ImageModal src={imagesPhilly.rivets1} alt="rivets-1" height="200"/>
          <ImageModal src={imagesPhilly.senate1} alt="senate-1" height="200"/>
          <ImageModal src={imagesPhilly.senate2} alt="senate-2" height="200"/>
          <ImageModal src={imagesPhilly.ships1} alt="ships-1" height="200"/>
          <ImageModal src={imagesPhilly.society1} alt="society-1" height="200"/>
          <ImageModal src={imagesPhilly.spruce1} alt="spruce-1" height="200"/>
          <ImageModal src={imagesPhilly.station1} alt="station-1" height="200"/>
          <ImageModal src={imagesPhilly.station2} alt="station-2" height="200"/>
          <ImageModal src={imagesPhilly.stonefishes1} alt="stonefishes-1" height="200"/>
          <ImageModal src={imagesPhilly.trashcan1} alt="trashcan-1" height="200"/>
          <ImageModal src={imagesPhilly.windows1} alt="windows-1" height="200"/>
          <ImageModal src={imagesPhilly.windows2} alt="windows-2" height="200"/>
          <ImageModal src={imagesPhilly.windows3} alt="windows-3" height="200"/>
          <ImageModal src={imagesPhilly.windows4} alt="windows-4" height="200"/>
        </div>
      }
    </>
  )
}
export default Philly