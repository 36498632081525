import { imagesVancouver } from '../components/imagesVancouver'
import ImageModal from '../components/ImageModal'

function Vancouver() {
  return (
    <>
      {!imagesVancouver && <div>Loading...</div>}
      {imagesVancouver &&
        <div className="images">
          <ImageModal src={imagesVancouver.arcade1} alt="arcade-1" height="200"/>
          <ImageModal src={imagesVancouver.bird1} alt="bird-1" height="200"/>
          <ImageModal src={imagesVancouver.boats1} alt="boats-1" height="200"/>
          <ImageModal src={imagesVancouver.cable1} alt="cable-1" height="200"/>
          <ImageModal src={imagesVancouver.dandelion1} alt="dandelion-1" height="200"/>
          <ImageModal src={imagesVancouver.duck1} alt="duck-1" height="200"/>
          <ImageModal src={imagesVancouver.flight1} alt="flight-1" height="200"/>
          <ImageModal src={imagesVancouver.flight2} alt="flight-2" height="200"/>
          <ImageModal src={imagesVancouver.flight3} alt="flight-3" height="200"/>
          <ImageModal src={imagesVancouver.flight4} alt="flight-4" height="200"/>
          <ImageModal src={imagesVancouver.forest1} alt="forest-1" height="200"/>
          <ImageModal src={imagesVancouver.forest2} alt="forest-2" height="200"/>
          <ImageModal src={imagesVancouver.forest3} alt="forest-3" height="200"/>
          <ImageModal src={imagesVancouver.forest4} alt="forest-4" height="200"/>
          <ImageModal src={imagesVancouver.geese1} alt="geese-1" height="200"/>
          <ImageModal src={imagesVancouver.horizon1} alt="horizon-1" height="200"/>
          <ImageModal src={imagesVancouver.horizon2} alt="horizon-2" height="200"/>
          <ImageModal src={imagesVancouver.hotelview1} alt="hotelview-1" height="200"/>
          <ImageModal src={imagesVancouver.hotelview2} alt="hotelview-2" height="200"/>
          <ImageModal src={imagesVancouver.hotelview3} alt="hotelview-3" height="200"/>
          <ImageModal src={imagesVancouver.hydrant1} alt="hydrant-1" height="200"/>
          <ImageModal src={imagesVancouver.lake1} alt="lake-1" height="200"/>
          <ImageModal src={imagesVancouver.leaf1} alt="leaf-1" height="200"/>
          <ImageModal src={imagesVancouver.leaf2} alt="leaf-2" height="200"/>
          <ImageModal src={imagesVancouver.leaf3} alt="leaf-3" height="200"/>
          <ImageModal src={imagesVancouver.mountains1} alt="mountains-1" height="200"/>
          <ImageModal src={imagesVancouver.mountains2} alt="mountains-2" height="200"/>
          <ImageModal src={imagesVancouver.paintcan1} alt="paintcan-1" height="200"/>
          <ImageModal src={imagesVancouver.path1} alt="path-1" height="200"/>
          <ImageModal src={imagesVancouver.pinkflower1} alt="pinkflower-1" height="200"/>
          <ImageModal src={imagesVancouver.pinkflower2} alt="pinkflower-2" height="200"/>
          <ImageModal src={imagesVancouver.pinkflower3} alt="pinkflower-3" height="200"/>
          <ImageModal src={imagesVancouver.post1} alt="post-1" height="200"/>
          <ImageModal src={imagesVancouver.raven1} alt="raven-1" height="200"/>
          <ImageModal src={imagesVancouver.redflower1} alt="redflower-1" height="200"/>
          <ImageModal src={imagesVancouver.redflower2} alt="redflower-2" height="200"/>
          <ImageModal src={imagesVancouver.redflower3} alt="redflower-3" height="200"/>
          <ImageModal src={imagesVancouver.redflower4} alt="redflower-4" height="200"/>
          <ImageModal src={imagesVancouver.redyellowflower1} alt="redyellowflower-1" height="200"/>
          <ImageModal src={imagesVancouver.redyellowflower2} alt="redyellowflower-2" height="200"/>
          <ImageModal src={imagesVancouver.squirrel1} alt="squirrel-1" height="200"/>
          <ImageModal src={imagesVancouver.texture1} alt="texture-1" height="200"/>
          <ImageModal src={imagesVancouver.totem1} alt="totem-1" height="200"/>
          <ImageModal src={imagesVancouver.totem2} alt="totem-2" height="200"/>
          <ImageModal src={imagesVancouver.totem3} alt="totem-3" height="200"/>
          <ImageModal src={imagesVancouver.tower1} alt="tower-1" height="200"/>
          <ImageModal src={imagesVancouver.tower2} alt="tower-2" height="200"/>
          <ImageModal src={imagesVancouver.whiteflower1} alt="whiteflower-1" height="200"/>
        </div>
      }
    </>
  )
}
export default Vancouver