import bwforest3 from '../../public/static/img/CH/winter/bwforest3.jpg'
import huts1 from '../../public/static/img/CH/engelberg/huts1.jpg'
import montebre3 from '../../public/static/img/CH/lugano/montebre3.jpg'
import peak2 from '../../public/static/img/CH/matterhorn/peak2.jpg'
import mountain9 from '../../public/static/img/CH/guarda/mountain9.jpg'
import vista25 from '../../public/static/img/CH/summer/mountains/vista25.jpg'

export const imagesCH = {
  bwforest3,
  huts1,
  montebre3,
  peak2,
  mountain9,
  vista25
}