import { imagesCH } from '../../components/imagesCH'
import Image from '../../components/Image'
import { Link } from "react-router-dom"

function CH() {
  return (
    <div className='images imagesHome'>   
      <div className="linkImage">
        <Link to='/engelberg'>
          <Image src={imagesCH.huts1} alt="engelberg" height="200"/>
          <p className="imageLabel">Engelberg</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/lugano'>
          <Image src={imagesCH.montebre3} alt="lugano" height="200"/>
          <p className="imageLabel">Lugano</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/guarda'>
          <Image src={imagesCH.mountain9} alt="guarda" height="200"/>
          <p className="imageLabel">Guarda</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/matterhorn'>
          <Image src={imagesCH.peak2} alt="matterhorn" height="200"/>
          <p className="imageLabel">Matterhorn</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/mountains'>
          <Image src={imagesCH.vista25} alt="mountains" height="200"/>
          <p className="imageLabel">Mountains</p>
        </Link>
      </div>
      <div className="linkImage">
        <Link to='/chwinter'>
          <Image src={imagesCH.bwforest3} alt="chwinter" height="200"/>
          <p className="imageLabel">Winter</p>
        </Link>
      </div>   
    </div>
  )
}
export default CH