import { imagesEverglades } from '../../components/US/imagesEverglades'
import ImageModal from '../../components/ImageModal'

function Everglades() {
  return (
    <>
      {!imagesEverglades && <div>Loading...</div>}
      {imagesEverglades &&       
        <div className="images">
          <ImageModal src={imagesEverglades.anhinga1} alt="anhinga-1" height="200"/>
          <ImageModal src={imagesEverglades.anhinga2} alt="anhinga-2" height="200"/>
          <ImageModal src={imagesEverglades.anhinga3} alt="anhinga-3" height="200"/>
          <ImageModal src={imagesEverglades.anhinga4} alt="anhinga-4" height="200"/>
          <ImageModal src={imagesEverglades.anhinga5} alt="anhinga-5" height="200"/>
          <ImageModal src={imagesEverglades.anhinga6} alt="anhinga-6" height="200"/>
          <ImageModal src={imagesEverglades.anhinga7} alt="anhinga-7" height="200"/>
          <ImageModal src={imagesEverglades.anhinga8} alt="anhinga-8" height="200"/>
          <ImageModal src={imagesEverglades.anhinga9} alt="anhinga-9" height="200"/>
          <ImageModal src={imagesEverglades.anhinga10} alt="anhinga-10" height="200"/>
          <ImageModal src={imagesEverglades.anhinga11} alt="anhinga-11" height="200"/>
          <ImageModal src={imagesEverglades.anhinga12} alt="anhinga-12" height="200"/>
          <ImageModal src={imagesEverglades.butterfly1} alt="butterfly-1" height="200"/>
          <ImageModal src={imagesEverglades.cormorant1} alt="cormorant-1" height="200"/>
          <ImageModal src={imagesEverglades.cormorant2} alt="cormorant-2" height="200"/>
          <ImageModal src={imagesEverglades.cormorant3} alt="cormorant-3" height="200"/>
          <ImageModal src={imagesEverglades.cormorant4} alt="cormorant-4" height="200"/>
          <ImageModal src={imagesEverglades.gator1} alt="gator-1" height="200"/>
          <ImageModal src={imagesEverglades.gator2} alt="gator-2" height="200"/>
          <ImageModal src={imagesEverglades.gator3} alt="gator-3" height="200"/>
          <ImageModal src={imagesEverglades.gator4} alt="gator-4" height="200"/>
          <ImageModal src={imagesEverglades.gator5} alt="gator-5" height="200"/>
          <ImageModal src={imagesEverglades.gator6} alt="gator-6" height="200"/>
          <ImageModal src={imagesEverglades.gator7} alt="gator-7" height="200"/>
          <ImageModal src={imagesEverglades.gator8} alt="gator-8" height="200"/>
          <ImageModal src={imagesEverglades.gator9} alt="gator-9" height="200"/>
          <ImageModal src={imagesEverglades.gator10} alt="gator-10" height="200"/>
          <ImageModal src={imagesEverglades.gator11} alt="gator-11" height="200"/>
          <ImageModal src={imagesEverglades.gator12} alt="gator-12" height="200"/>
          <ImageModal src={imagesEverglades.gator13} alt="gator-13" height="200"/>
          <ImageModal src={imagesEverglades.gator14} alt="gator-14" height="200"/>
          <ImageModal src={imagesEverglades.gator15} alt="gator-15" height="200"/>
          <ImageModal src={imagesEverglades.gator16} alt="gator-16" height="200"/>
          <ImageModal src={imagesEverglades.gator17} alt="gator-17" height="200"/>
          <ImageModal src={imagesEverglades.gator18} alt="gator-18" height="200"/>
          <ImageModal src={imagesEverglades.gator19} alt="gator-19" height="200"/>
          <ImageModal src={imagesEverglades.gator20} alt="gator-20" height="200"/>
          <ImageModal src={imagesEverglades.gator21} alt="gator-21" height="200"/>
          <ImageModal src={imagesEverglades.gator22} alt="gator-22" height="200"/>
          <ImageModal src={imagesEverglades.gator23} alt="gator-23" height="200"/>
          <ImageModal src={imagesEverglades.gator24} alt="gator-24" height="200"/>
          <ImageModal src={imagesEverglades.gator25} alt="gator-25" height="200"/>
          <ImageModal src={imagesEverglades.gator26} alt="gator-26" height="200"/>
          <ImageModal src={imagesEverglades.gator27} alt="gator-27" height="200"/>
          <ImageModal src={imagesEverglades.gator28} alt="gator-28" height="200"/>
          <ImageModal src={imagesEverglades.gator29} alt="gator-29" height="200"/>
          <ImageModal src={imagesEverglades.gator30} alt="gator-30" height="200"/>
          <ImageModal src={imagesEverglades.grasshopper1} alt="grasshopper-1" height="200"/>
          <ImageModal src={imagesEverglades.grasshopper2} alt="grasshopper-2" height="200"/>
          <ImageModal src={imagesEverglades.gull1} alt="gull-1" height="200"/>
          <ImageModal src={imagesEverglades.gull2} alt="gull-2" height="200"/>
          <ImageModal src={imagesEverglades.heron1} alt="heron-1" height="200"/>
          <ImageModal src={imagesEverglades.heron2} alt="heron-2" height="200"/>
          <ImageModal src={imagesEverglades.heron3} alt="heron-3" height="200"/>
          <ImageModal src={imagesEverglades.heron4} alt="heron-4" height="200"/>
          <ImageModal src={imagesEverglades.heron5} alt="heron-5" height="200"/>
          <ImageModal src={imagesEverglades.heron6} alt="heron-6" height="200"/>
          <ImageModal src={imagesEverglades.heron7} alt="heron-7" height="200"/>
          <ImageModal src={imagesEverglades.heron8} alt="heron-8" height="200"/>
          <ImageModal src={imagesEverglades.heron9} alt="heron-9" height="200"/>
          <ImageModal src={imagesEverglades.heron10} alt="heron-10" height="200"/>
          <ImageModal src={imagesEverglades.heron11} alt="heron-11" height="200"/>
          <ImageModal src={imagesEverglades.heron12} alt="heron-12" height="200"/>
          <ImageModal src={imagesEverglades.heron13} alt="heron-13" height="200"/>
          <ImageModal src={imagesEverglades.ibis1} alt="ibis-1" height="200"/>
          <ImageModal src={imagesEverglades.ibis2} alt="ibis-2" height="200"/>
          <ImageModal src={imagesEverglades.ibis3} alt="ibis-3" height="200"/>
          <ImageModal src={imagesEverglades.leaf1} alt="leaf-1" height="200"/>
          <ImageModal src={imagesEverglades.leaf2} alt="leaf-2" height="200"/>
          <ImageModal src={imagesEverglades.lizard1} alt="lizard-1" height="200"/>
          <ImageModal src={imagesEverglades.lizard2} alt="lizard-2" height="200"/>
          <ImageModal src={imagesEverglades.lizard3} alt="lizard-3" height="200"/>
          <ImageModal src={imagesEverglades.lizard4} alt="lizard-4" height="200"/>
          <ImageModal src={imagesEverglades.turtle1} alt="turtle-1" height="200"/>
          <ImageModal src={imagesEverglades.turtle2} alt="turtle-2" height="200"/>
          <ImageModal src={imagesEverglades.vulture1} alt="vulture-1" height="200"/>
          <ImageModal src={imagesEverglades.vulture2} alt="vulture-2" height="200"/>
          <ImageModal src={imagesEverglades.vulture3} alt="vulture-3" height="200"/>
          <ImageModal src={imagesEverglades.walkway1} alt="walkway-1" height="200"/>
          <ImageModal src={imagesEverglades.wood1} alt="wood-1" height="200"/>
        </div>
      }
    </>
  )
}
export default Everglades