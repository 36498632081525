import { imagesAntelope } from '../../../components/US/West/imagesAntelope'
import ImageModal from '../../../components/ImageModal'

function Antelope() {
  return (
    <>
      {!imagesAntelope && <div>Loading...</div>}
      {imagesAntelope && 
        <div className="images">
          <ImageModal src={imagesAntelope.antelope1} alt="antelope-1" height="200"/>
          <ImageModal src={imagesAntelope.antelope2} alt="antelope-2" height="200"/>
          <ImageModal src={imagesAntelope.antelope3} alt="antelope-3" height="200"/>
          <ImageModal src={imagesAntelope.antelope4} alt="antelope-4" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope1} alt="bwantelope-1" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope2} alt="bwantelope-2" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope3} alt="bwantelope-3" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope4} alt="bwantelope-4" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope5} alt="bwantelope-5" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope6} alt="bwantelope-6" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope7} alt="bwantelope-7" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope8} alt="bwantelope-8" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope9} alt="bwantelope-9" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope10} alt="bwantelope-10" height="200"/>
          <ImageModal src={imagesAntelope.bwantelope11} alt="bwantelope-11" height="200"/>
        </div>
      }
    </>
  )
}
export default Antelope