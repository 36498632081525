import { imagesHome } from '../components/imagesHome'
import Image from '../components/Image'
import { Link } from "react-router-dom"

function Home() {
  return (
    <>
      {!imagesHome && <div>Loading...</div>}
      {imagesHome && 
        <div className='images imagesHome'>
          <div className="linkImage">
            <Link to='/abstract'>
              <Image src={imagesHome.bench2} alt="abstract" height="200"/>
              <p className="imageLabel">Abstract</p>
            </Link>       
          </div>
          <div className="linkImage">
            <Link to='/colour'>
              <Image src={imagesHome.blue2} alt="colour" height="200"/>
              <p className="imageLabel">Colour</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/wildlife'>
              <Image src={imagesHome.antelope1} alt="wildlife" height="200"/>
              <p className="imageLabel">Wildlife</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/rome'>
              <Image src={imagesHome.statue1} alt="rome" height="200"/>
              <p className="imageLabel">Rome</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/malta'>
              <Image src={imagesHome.lion2} alt="malta" height="200"/>
              <p className="imageLabel">Malta</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/bruges'>
              <Image src={imagesHome.faces1} alt="bruges" height="200"/>
              <p className="imageLabel">Bruges</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/barcelona'>
              <Image src={imagesHome.door1} alt="barcelona" height="200"/>
              <p className="imageLabel">Barcelona</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/vancouver'>
              <Image src={imagesHome.tower1} alt="vancouver" height="200"/>
              <p className="imageLabel">Vancouver</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/kew'>
              <Image src={imagesHome.avenue1} alt="kew" height="200"/>
              <p className="imageLabel">Kew</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/everglades'>
              <Image src={imagesHome.gator20} alt="everglades" height="200"/>
              <p className="imageLabel">Everglades</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/miami'>
              <Image src={imagesHome.artdeco2} alt="miami" height="200"/>
              <p className="imageLabel">Miami</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/dc'>
              <Image src={imagesHome.memorial2} alt="dc" height="200"/>
              <p className="imageLabel">Washington DC</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/philly'>
              <Image src={imagesHome.phillyskyline2} alt="philly" height="200"/>
              <p className="imageLabel">Philadelphia</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/nyc'>
              <Image src={imagesHome.chrysler4} alt="nyc" height="200"/>
              <p className="imageLabel">New York</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/smoky'>
              <Image src={imagesHome.mountains2} alt="smoky" height="200"/>
              <p className="imageLabel">Smoky Mountains</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/vegas'>
              <Image src={imagesHome.strat2} alt="vegas" height="200"/>
              <p className="imageLabel">Las Vegas</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/hoover'>
              <Image src={imagesHome.fulldam3} alt="hoover" height="200"/>
              <p className="imageLabel">Hoover Dam</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/antelope'>
              <Image src={imagesHome.bwantelope5} alt="antelope" height="200"/>
              <p className="imageLabel">Antelope Canyon</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/horseshoe'>
              <Image src={imagesHome.horseshoe2} alt="horseshoe" height="200"/>
              <p className="imageLabel">Horseshoe Bend</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/yosemite'>
              <Image src={imagesHome.halfdome5} alt="yosemite" height="200"/>
              <p className="imageLabel">Yosemite</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/zion'>
              <Image src={imagesHome.mountains7} alt="zion" height="200"/>
              <p className="imageLabel">Zion Canyon</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/sr1'>
              <Image src={imagesHome.coast10} alt="sr1" height="200"/>
              <p className="imageLabel">State Route 1</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/grandcanyon'>
              <Image src={imagesHome.tree3} alt="grandcanyon" height="200"/>
              <p className="imageLabel">Grand Canyon</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/bryce'>
              <Image src={imagesHome.hoodoo3} alt="bryce" height="200"/>
              <p className="imageLabel">Bryce Canyon</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/moab'>
              <Image src={imagesHome.arch3} alt="moab" height="200"/>
              <p className="imageLabel">Arches & Canyonlands</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/deathvalley'>
              <Image src={imagesHome.zabriskie5} alt="deathvalley" height="200"/>
              <p className="imageLabel">Death Valley</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/engelberg'>
              <Image src={imagesHome.huts1} alt="engelberg" height="200"/>
              <p className="imageLabel">Engelberg</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/lugano'>
              <Image src={imagesHome.montebre3} alt="lugano" height="200"/>
              <p className="imageLabel">Lugano</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/guarda'>
              <Image src={imagesHome.mountain9} alt="guarda" height="200"/>
              <p className="imageLabel">Guarda</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/matterhorn'>
              <Image src={imagesHome.peak2} alt="matterhorn" height="200"/>
              <p className="imageLabel">Matterhorn</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/mountains'>
              <Image src={imagesHome.vista25} alt="mountains" height="200"/>
              <p className="imageLabel">Mountains</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/chwinter'>
              <Image src={imagesHome.bwforest3} alt="chwinter" height="200"/>
              <p className="imageLabel">Winter</p>
            </Link>
          </div>
        </div>
      }
    </>
  )
}
export default Home