import { imagesAbstract } from '../components/imagesAbstract'
import ImageModal from '../components/ImageModal'

function Abstract() {
  return (
    <>
      {!imagesAbstract && <div>Loading...</div>}
      {imagesAbstract &&
        <div className="images">
          <ImageModal src={imagesAbstract.aluminium1} alt="aluminium-1" height="200"/>
          <ImageModal src={imagesAbstract.bench1} alt="bench-1" height="200"/>
          <ImageModal src={imagesAbstract.bench2} alt="bench-2" height="200"/>
          <ImageModal src={imagesAbstract.bunderberg1} alt="bunderberg-1" height="200"/>
          <ImageModal src={imagesAbstract.bunderberg2} alt="bunderberg-2" height="200"/>
          <ImageModal src={imagesAbstract.cabinet1} alt="cabinet-1" height="200"/>
          <ImageModal src={imagesAbstract.case1} alt="case-1" height="200"/>
          <ImageModal src={imagesAbstract.case2} alt="case-2" height="200"/>
          <ImageModal src={imagesAbstract.chair1} alt="chair-1" height="200"/>
          <ImageModal src={imagesAbstract.coin1} alt="coin-1" height="200"/>
          <ImageModal src={imagesAbstract.coin2} alt="coin-2" height="200"/>
          <ImageModal src={imagesAbstract.coin3} alt="coin-3" height="200"/>
          <ImageModal src={imagesAbstract.coin4} alt="coin-4" height="200"/>
          <ImageModal src={imagesAbstract.cufflinks1} alt="cufflinks-1" height="200"/>
          <ImageModal src={imagesAbstract.cursive1} alt="cursive-1" height="200"/>
          <ImageModal src={imagesAbstract.cushion2} alt="cushion-2" height="200"/>
          <ImageModal src={imagesAbstract.gatepost1} alt="gatepost-1" height="200"/>
          <ImageModal src={imagesAbstract.gilded1} alt="gilded-1" height="200"/>
          <ImageModal src={imagesAbstract.hydrant1} alt="hydrant-1" height="200"/>
          <ImageModal src={imagesAbstract.lock1} alt="lock-1" height="200"/>
          <ImageModal src={imagesAbstract.mirror1} alt="mirror-1" height="200"/>
          <ImageModal src={imagesAbstract.cushion1} alt="cushion-1" height="200"/>
          <ImageModal src={imagesAbstract.note1} alt="note-1" height="200"/>
          <ImageModal src={imagesAbstract.note2} alt="note-2" height="200"/>
          <ImageModal src={imagesAbstract.pipe2} alt="pipe-2" height="200"/>
          <ImageModal src={imagesAbstract.pint1} alt="pint-1" height="200"/>
          <ImageModal src={imagesAbstract.pipe1} alt="pipe-1" height="200"/>
          <ImageModal src={imagesAbstract.post1} alt="post-1" height="200"/>
          <ImageModal src={imagesAbstract.post2} alt="post-2" height="200"/>
          <ImageModal src={imagesAbstract.rails1} alt="rails-1" height="200"/>
          <ImageModal src={imagesAbstract.rings1} alt="rings-1" height="200"/>
          <ImageModal src={imagesAbstract.rocks1} alt="rocks-1" height="200"/>
          <ImageModal src={imagesAbstract.sofa1} alt="sofa-1" height="200"/>
          <ImageModal src={imagesAbstract.symmetry1} alt="symmetry-1" height="200"/>
          <ImageModal src={imagesAbstract.symmetry2} alt="symmetry-2" height="200"/>
          <ImageModal src={imagesAbstract.wall1} alt="wall-1" height="200"/>
          <ImageModal src={imagesAbstract.wall2} alt="wall-2" height="200"/>
          <ImageModal src={imagesAbstract.wall3} alt="wall-3" height="200"/>
          <ImageModal src={imagesAbstract.walnuts1} alt="walnuts-1" height="200"/>
          <ImageModal src={imagesAbstract.watch1} alt="watch-1" height="200"/>
          <ImageModal src={imagesAbstract.watch2} alt="watch-2" height="200"/>
          <ImageModal src={imagesAbstract.wood1} alt="wood-1" height="200"/>
        </div>
      }
    </>
  )
}
export default Abstract