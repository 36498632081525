import { imagesColour } from '../components/imagesColour'
import ImageModal from '../components/ImageModal'

function Colour() {
  return (
    <>
      {!imagesColour && <div>Loading...</div>}
      {imagesColour && 
        <div className="images">
          <ImageModal src={imagesColour.blue1} alt="blue-1" height="200"/>
          <ImageModal src={imagesColour.blue2} alt="blue-2" height="200"/>
          <ImageModal src={imagesColour.bw1} alt="bw-1" height="200"/>
          <ImageModal src={imagesColour.green1} alt="green-1" height="200"/>
          <ImageModal src={imagesColour.greenpink1} alt="green-pink-1" height="200"/>
          <ImageModal src={imagesColour.greenpink2} alt="green-pink-2" height="200"/>
          <ImageModal src={imagesColour.orange1} alt="orange-1" height="200"/>
          <ImageModal src={imagesColour.orange2} alt="orange-2" height="200"/>
          <ImageModal src={imagesColour.orange3} alt="orange-3" height="200"/>
          <ImageModal src={imagesColour.orangeblue1} alt="orange-blue-1" height="200"/>
          <ImageModal src={imagesColour.pink2} alt="pink-2" height="200"/>
          <ImageModal src={imagesColour.pink5} alt="pink-5" height="200"/>
          <ImageModal src={imagesColour.pink6} alt="pink-6" height="200"/>
          <ImageModal src={imagesColour.pink7} alt="pink-7" height="200"/>
          <ImageModal src={imagesColour.pinkwhite1} alt="pink-white-1" height="200"/>
          <ImageModal src={imagesColour.pinkwhite2} alt="pink-white-2" height="200"/>
          <ImageModal src={imagesColour.purple1} alt="purple-1" height="200"/>
          <ImageModal src={imagesColour.purple2} alt="purple-2" height="200"/>
          <ImageModal src={imagesColour.purple3} alt="purple-3" height="200"/>
          <ImageModal src={imagesColour.purple4} alt="purple-4" height="200"/>
          <ImageModal src={imagesColour.purple5} alt="purple-5" height="200"/>
          <ImageModal src={imagesColour.purple6} alt="purple-6" height="200"/>
          <ImageModal src={imagesColour.purpleyellow1} alt="purple-yellow-1" height="200"/>
          <ImageModal src={imagesColour.red1} alt="red-1" height="200"/>
          <ImageModal src={imagesColour.red2} alt="red-2" height="200"/>
          <ImageModal src={imagesColour.red3} alt="red-3" height="200"/>
          <ImageModal src={imagesColour.red4} alt="red-4" height="200"/>
          <ImageModal src={imagesColour.red5} alt="red-5" height="200"/>
          <ImageModal src={imagesColour.redgreen1} alt="red-green-1" height="200"/>
          <ImageModal src={imagesColour.redyellow1} alt="red-yellow-1" height="200"/>
          <ImageModal src={imagesColour.redyellow2} alt="red-yellow-2" height="200"/>
          <ImageModal src={imagesColour.redyellow3} alt="red-yellow-3" height="200"/>
          <ImageModal src={imagesColour.white1} alt="white-1" height="200"/>
          <ImageModal src={imagesColour.whitepink1} alt="white-pink-1" height="200"/>
          <ImageModal src={imagesColour.whitepink2} alt="white-pink-2" height="200"/>
          <ImageModal src={imagesColour.yellow1} alt="yellow-1" height="200"/>
          <ImageModal src={imagesColour.yellow2} alt="yellow-2" height="200"/>
          <ImageModal src={imagesColour.bw2} alt="bw-2" height="200"/>
          <ImageModal src={imagesColour.pink1} alt="pink-1" height="200"/>
          <ImageModal src={imagesColour.pink3} alt="pink-3" height="200"/>
          <ImageModal src={imagesColour.pink4} alt="pink-4" height="200"/>
        </div>
      }
    </>
  )
}
export default Colour