import gator20 from '../../public/static/img/US/everglades/gator20.jpg'
import artdeco2 from '../../public/static/img/US/miami/artdeco2.jpg'
import memorial2 from '../../public/static/img/US/northeast/dc/memorial2.jpg'
import chrysler4 from '../../public/static/img/US/northeast/nyc/chrysler4.jpg'
import phillyskyline2 from '../../public/static/img/US/northeast/philly/phillyskyline2.jpg'
import mountains2 from '../../public/static/img/US/northeast/smokymountains/mountains2.jpg'
import strat2 from '../../public/static/img/US/west/vegas/strat2.jpg'
import bwantelope5 from '../../public/static/img/US/west/antelope/bwantelope5.jpg'
import horseshoe2 from '../../public/static/img/US/west/horseshoe/horseshoe2.jpg'
import halfdome5 from '../../public/static/img/US/west/yosemite/halfdome5.jpg'
import mountains7 from '../../public/static/img/US/west/zion/mountains7.jpg'
import fulldam3 from '../../public/static/img/US/west/hoover/fulldam3.jpg'
import coast10 from '../../public/static/img/US/west/sr1/coast10.jpg'
import tree3 from '../../public/static/img/US/west/grandcanyon/tree3.jpg'
import hoodoo3 from '../../public/static/img/US/west/bryce/hoodoo3.jpg'
import arch3 from '../../public/static/img/US/west/moab/arch3.jpg'
import zabriskie5 from '../../public/static/img/US/west/dv/zabriskie5.jpg'

export const imagesUS = {
  gator20,
  artdeco2,
  memorial2,
  chrysler4,
  phillyskyline2,
  mountains2,
  strat2,
  bwantelope5,
  horseshoe2,
  halfdome5,
  mountains7,
  fulldam3,
  coast10,
  tree3,
  hoodoo3,
  arch3,
  zabriskie5
}