import { imagesSR1 } from '../../../components/US/West/imagesSR1'
import ImageModal from '../../../components/ImageModal'

function SR1() {
  return (
    <>
      {!imagesSR1 && <div>Loading...</div>}
      {imagesSR1 && 
        <div className="images">
          <ImageModal src={imagesSR1.beach1} alt="beach-1" height="200"/>
          <ImageModal src={imagesSR1.bridge1} alt="bridge-1" height="200"/>
          <ImageModal src={imagesSR1.coast1} alt="coast-1" height="200"/>
          <ImageModal src={imagesSR1.coast2} alt="coast-2" height="200"/>
          <ImageModal src={imagesSR1.coast3} alt="coast-3" height="200"/>
          <ImageModal src={imagesSR1.coast4} alt="coast-4" height="200"/>
          <ImageModal src={imagesSR1.coast5} alt="coast-5" height="200"/>
          <ImageModal src={imagesSR1.coast6} alt="coast-6" height="200"/>
          <ImageModal src={imagesSR1.coast7} alt="coast-7" height="200"/>
          <ImageModal src={imagesSR1.coast8} alt="coast-8" height="200"/>
          <ImageModal src={imagesSR1.coast9} alt="coast-9" height="200"/>
          <ImageModal src={imagesSR1.coast10} alt="coast-10" height="200"/>
          <ImageModal src={imagesSR1.coast11} alt="coast-11" height="200"/>
          <ImageModal src={imagesSR1.coast12} alt="coast-12" height="200"/>
          <ImageModal src={imagesSR1.coast13} alt="coast-13" height="200"/>
          <ImageModal src={imagesSR1.coast14} alt="coast-14" height="200"/>
          <ImageModal src={imagesSR1.coast15} alt="coast-15" height="200"/>
          <ImageModal src={imagesSR1.hills1} alt="hills-1" height="200"/>
          <ImageModal src={imagesSR1.hills2} alt="hills-2" height="200"/>
          <ImageModal src={imagesSR1.hills3} alt="hills-3" height="200"/>
          <ImageModal src={imagesSR1.ocean1} alt="ocean-1" height="200"/>
          <ImageModal src={imagesSR1.ocean2} alt="ocean-2" height="200"/>
          <ImageModal src={imagesSR1.rodent1} alt="rodent-1" height="200"/>
          <ImageModal src={imagesSR1.seal1} alt="seal-1" height="200"/>
          <ImageModal src={imagesSR1.seals1} alt="seals-1" height="200"/>
          <ImageModal src={imagesSR1.seals2} alt="seals-2" height="200"/>
          <ImageModal src={imagesSR1.seals3} alt="seals-3" height="200"/>
          <ImageModal src={imagesSR1.seals4} alt="seals-4" height="200"/>
          <ImageModal src={imagesSR1.seals5} alt="seals-5" height="200"/>
          <ImageModal src={imagesSR1.seals6} alt="seals-6" height="200"/>
          <ImageModal src={imagesSR1.seals7} alt="seals-7" height="200"/>
          <ImageModal src={imagesSR1.seals8} alt="seals-8" height="200"/>
          <ImageModal src={imagesSR1.seals9} alt="seals-9" height="200"/>
          <ImageModal src={imagesSR1.seals10} alt="seals-10" height="200"/>
        </div>
      }
    </>
  )
}
export default SR1