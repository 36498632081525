import bench2 from '../../public/static/img/abstract/bench2.jpg' 
import statue1 from '../../public/static/img/Europe/rome/statue1.jpg'
import lion2 from '../../public/static/img/Europe/malta/lion2.jpg'
import faces1 from '../../public/static/img/Europe/bruges/faces1.jpg'
import door1 from '../../public/static/img/Europe/barcelona/door1.jpg'
import blue2 from '../../public/static/img/colour/blue2.jpg'
import antelope1 from '../../public/static/img/wildlife/antelope1.jpg'
import tower1 from '../../public/static/img/vancouver/tower1.jpg'
import gator20 from '../../public/static/img/US/everglades/gator20.jpg'
import memorial2 from '../../public/static/img/US/northeast/dc/memorial2.jpg'
import chrysler4 from '../../public/static/img/US/northeast/nyc/chrysler4.jpg'
import phillyskyline2 from '../../public/static/img/US/northeast/philly/phillyskyline2.jpg'
import mountains2 from '../../public/static/img/US/northeast/smokymountains/mountains2.jpg'
import artdeco2 from '../../public/static/img/US/miami/artdeco2.jpg'
import avenue1 from '../../public/static/img/kew/avenue1.jpg'
import strat2 from '../../public/static/img/US/west/vegas/strat2.jpg'
import bwantelope5 from '../../public/static/img/US/west/antelope/bwantelope5.jpg'
import horseshoe2 from '../../public/static/img/US/west/horseshoe/horseshoe2.jpg'
import halfdome5 from '../../public/static/img/US/west/yosemite/halfdome5.jpg'
import mountains7 from '../../public/static/img/US/west/zion/mountains7.jpg'
import fulldam3 from '../../public/static/img/US/west/hoover/fulldam3.jpg'
import coast10 from '../../public/static/img/US/west/sr1/coast10.jpg'
import tree3 from '../../public/static/img/US/west/grandcanyon/tree3.jpg'
import hoodoo3 from '../../public/static/img/US/west/bryce/hoodoo3.jpg'
import arch3 from '../../public/static/img/US/west/moab/arch3.jpg'
import zabriskie5 from '../../public/static/img/US/west/dv/zabriskie5.jpg'
import bwforest3 from '../../public/static/img/CH/winter/bwforest3.jpg'
import huts1 from '../../public/static/img/CH/engelberg/huts1.jpg'
import montebre3 from '../../public/static/img/CH/lugano/montebre3.jpg'
import peak2 from '../../public/static/img/CH/matterhorn/peak2.jpg'
import mountain9 from '../../public/static/img/CH/guarda/mountain9.jpg'
import vista25 from '../../public/static/img/CH/summer/mountains/vista25.jpg'

export const imagesHome = {
  bench2,
  statue1,
  lion2,
  faces1,
  door1,
  blue2,
  antelope1,
  tower1,
  gator20,
  memorial2,
  chrysler4,
  phillyskyline2,
  mountains2,
  artdeco2,
  avenue1,
  strat2,
  bwantelope5,
  horseshoe2,
  halfdome5,
  mountains7,
  fulldam3, 
  coast10,
  tree3,
  hoodoo3,
  arch3,
  zabriskie5,
  bwforest3,
  huts1,
  montebre3,
  peak2,
  mountain9,
  vista25
}