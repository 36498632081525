import { imagesMiami } from '../../components/US/imagesMiami'
import ImageModal from '../../components/ImageModal'

function Miami() {
  return (
    <>
      {!imagesMiami && <div>Loading...</div>}
      {imagesMiami && 
        <div className="images">
          <ImageModal src={imagesMiami.apartments1} alt="apartments-1" height="200"/>
          <ImageModal src={imagesMiami.apartments2} alt="apartments-2" height="200"/>
          <ImageModal src={imagesMiami.artdeco1} alt="artdeco-1" height="200"/>
          <ImageModal src={imagesMiami.artdeco2} alt="artdeco-2" height="200"/>
          <ImageModal src={imagesMiami.artdeco3} alt="artdeco-3" height="200"/>
          <ImageModal src={imagesMiami.artdeco4} alt="artdeco-4" height="200"/>
          <ImageModal src={imagesMiami.artdeco5} alt="artdeco-5" height="200"/>
          <ImageModal src={imagesMiami.artdeco6} alt="artdeco-6" height="200"/>
          <ImageModal src={imagesMiami.artdeco7} alt="artdeco-7" height="200"/>
          <ImageModal src={imagesMiami.artdeco8} alt="artdeco-8" height="200"/>
          <ImageModal src={imagesMiami.beach1} alt="beach-1" height="200"/>
          <ImageModal src={imagesMiami.beach2} alt="beach-2" height="200"/>
          <ImageModal src={imagesMiami.beach3} alt="beach-3" height="200"/>
          <ImageModal src={imagesMiami.beach4} alt="beach-4" height="200"/>
          <ImageModal src={imagesMiami.beach5} alt="beach-5" height="200"/>
          <ImageModal src={imagesMiami.beach6} alt="beach-6" height="200"/>
          <ImageModal src={imagesMiami.beach7} alt="beach-7" height="200"/>
          <ImageModal src={imagesMiami.beach8} alt="beach-8" height="200"/>
          <ImageModal src={imagesMiami.beach9} alt="beach-9" height="200"/>
          <ImageModal src={imagesMiami.beach10} alt="beach-10" height="200"/>
          <ImageModal src={imagesMiami.beach11} alt="beach-11" height="200"/>
          <ImageModal src={imagesMiami.beach12} alt="beach-12" height="200"/>
          <ImageModal src={imagesMiami.beach13} alt="beach-13" height="200"/>
          <ImageModal src={imagesMiami.boatdive1} alt="boatdive-1" height="200"/>
          <ImageModal src={imagesMiami.boatdive2} alt="boatdive-2" height="200"/>
          <ImageModal src={imagesMiami.boatdive3} alt="boatdive-3" height="200"/>
          <ImageModal src={imagesMiami.boatdive4} alt="boatdive-4" height="200"/>
          <ImageModal src={imagesMiami.classic1} alt="classic-1" height="200"/>
          <ImageModal src={imagesMiami.corvette1} alt="corvette-1" height="200"/>
          <ImageModal src={imagesMiami.corvette2} alt="corvette-2" height="200"/>
          <ImageModal src={imagesMiami.corvette3} alt="corvette-3" height="200"/>
          <ImageModal src={imagesMiami.dock1} alt="dock-1" height="200"/>
          <ImageModal src={imagesMiami.flight1} alt="flight-1" height="200"/>
          <ImageModal src={imagesMiami.flight2} alt="flight-2" height="200"/>
          <ImageModal src={imagesMiami.highway1} alt="highway-1" height="200"/>
          <ImageModal src={imagesMiami.highway2} alt="highway-2" height="200"/>
          <ImageModal src={imagesMiami.keywest1} alt="keywest-1" height="200"/>
          <ImageModal src={imagesMiami.mustang1} alt="mustang-1" height="200"/>
          <ImageModal src={imagesMiami.oceandrive1} alt="oceandrive-1" height="200"/>
          <ImageModal src={imagesMiami.oceandrive2} alt="oceandrive-2" height="200"/>
          <ImageModal src={imagesMiami.oceandrive3} alt="oceandrive-3" height="200"/>
          <ImageModal src={imagesMiami.oceandrive4} alt="oceandrive-4" height="200"/>
          <ImageModal src={imagesMiami.oceandrive5} alt="oceandrive-5" height="200"/>
          <ImageModal src={imagesMiami.oceandrive6} alt="oceandrive-6" height="200"/>
          <ImageModal src={imagesMiami.oceandrive7} alt="oceandrive-7" height="200"/>
          <ImageModal src={imagesMiami.oceandrive8} alt="oceandrive-8" height="200"/>
          <ImageModal src={imagesMiami.oceandrive9} alt="oceandrive-9" height="200"/>
          <ImageModal src={imagesMiami.oceandrive10} alt="oceandrive-10" height="200"/>
          <ImageModal src={imagesMiami.oceandrive11} alt="oceandrive-11" height="200"/>
          <ImageModal src={imagesMiami.palm1} alt="palm-1" height="200"/>
          <ImageModal src={imagesMiami.palm2} alt="palm-2" height="200"/>
          <ImageModal src={imagesMiami.palm3} alt="palm-3" height="200"/>
          <ImageModal src={imagesMiami.palm4} alt="palm-4" height="200"/>
          <ImageModal src={imagesMiami.palm5} alt="palm-5" height="200"/>
          <ImageModal src={imagesMiami.palm6} alt="palm-6" height="200"/>
          <ImageModal src={imagesMiami.palm7} alt="palm-7" height="200"/>
          <ImageModal src={imagesMiami.palm8} alt="palm-8" height="200"/>
          <ImageModal src={imagesMiami.palm9} alt="palm-9" height="200"/>
          <ImageModal src={imagesMiami.palm10} alt="palm-10" height="200"/>
          <ImageModal src={imagesMiami.palm11} alt="palm-11" height="200"/>
          <ImageModal src={imagesMiami.palm12} alt="palm-12" height="200"/>
          <ImageModal src={imagesMiami.palm13} alt="palm-13" height="200"/>
          <ImageModal src={imagesMiami.square1} alt="square-1" height="200"/>
          <ImageModal src={imagesMiami.square2} alt="square-2" height="200"/>
          <ImageModal src={imagesMiami.stairs1} alt="stairs-1" height="200"/>
          <ImageModal src={imagesMiami.surf1} alt="surf-1" height="200"/>
        </div>
      }    
    </>
  )
}
export default Miami