import { imagesBryce } from '../../../components/US/West/imagesBryce'
import ImageModal from '../../../components/ImageModal'

function Bryce() {
  return (
    <>
      {!imagesBryce && <div>Loading...</div>}
      {imagesBryce && 
        <div className="images">
          <ImageModal src={imagesBryce.butte1} alt="butte-1" height="200"/>
          <ImageModal src={imagesBryce.bwclouds1} alt="bwclouds-1" height="200"/>
          <ImageModal src={imagesBryce.bwhoodoo1} alt="bwhoodoo-1" height="200"/>
          <ImageModal src={imagesBryce.bwhoodoo2} alt="bwhoodoo-2" height="200"/>
          <ImageModal src={imagesBryce.bwhorizon1} alt="bwhorizon-1" height="200"/>
          <ImageModal src={imagesBryce.hoodoo1} alt="hoodoo-1" height="200"/>
          <ImageModal src={imagesBryce.hoodoo2} alt="hoodoo-2" height="200"/>
          <ImageModal src={imagesBryce.hoodoo3} alt="hoodoo-3" height="200"/>
          <ImageModal src={imagesBryce.hoodoo4} alt="hoodoo-4" height="200"/>
          <ImageModal src={imagesBryce.hoodoo5} alt="hoodoo-5" height="200"/>
          <ImageModal src={imagesBryce.hoodoo6} alt="hoodoo-6" height="200"/>
          <ImageModal src={imagesBryce.hoodoo7} alt="hoodoo-7" height="200"/>
          <ImageModal src={imagesBryce.hoodoo8} alt="hoodoo-8" height="200"/>
          <ImageModal src={imagesBryce.hoodoo9} alt="hoodoo-9" height="200"/>
          <ImageModal src={imagesBryce.horizon1} alt="horizon-1" height="200"/>
          <ImageModal src={imagesBryce.horizon2} alt="horizon-2" height="200"/>
          <ImageModal src={imagesBryce.horizon3} alt="horizon-3" height="200"/>
          <ImageModal src={imagesBryce.horizon4} alt="horizon-4" height="200"/>
          <ImageModal src={imagesBryce.horizon5} alt="horizon-5" height="200"/>
          <ImageModal src={imagesBryce.valley1} alt="valley-1" height="200"/>
        </div>
      }
    </>
  )
}
export default Bryce