import { imagesDV } from '../../../components/US/West/imagesDV'
import ImageModal from '../../../components/ImageModal'

function DeathValley() {
  return (
    <>
      {!imagesDV && <div>Loading...</div>}
      {imagesDV && 
        <div className="images">
          <ImageModal src={imagesDV.badwater1} alt="badwater-1" height="200"/>
          <ImageModal src={imagesDV.badwater2} alt="badwater-2" height="200"/>
          <ImageModal src={imagesDV.badwater3} alt="badwater-3" height="200"/>
          <ImageModal src={imagesDV.badwater4} alt="badwater-4" height="200"/>
          <ImageModal src={imagesDV.benz1} alt="benz-1" height="200"/>
          <ImageModal src={imagesDV.benz2} alt="benz-2" height="200"/>
          <ImageModal src={imagesDV.bwzabriskie1} alt="bwzabriskie-1" height="200"/>
          <ImageModal src={imagesDV.camaro1} alt="camaro-1" height="200"/>
          <ImageModal src={imagesDV.camaro2} alt="camaro-2" height="200"/>
          <ImageModal src={imagesDV.camaro3} alt="camaro-3" height="200"/>
          <ImageModal src={imagesDV.clouds1} alt="clouds-1" height="200"/>
          <ImageModal src={imagesDV.colourrocks1} alt="colourrocks-1" height="200"/>
          <ImageModal src={imagesDV.colourrocks2} alt="colourrocks-2" height="200"/>
          <ImageModal src={imagesDV.colourrocks3} alt="colourrocks-3" height="200"/>
          <ImageModal src={imagesDV.dunes1} alt="dunes-1" height="200"/>
          <ImageModal src={imagesDV.highway1} alt="highway-1" height="200"/>
          <ImageModal src={imagesDV.highway2} alt="highway-2" height="200"/>
          <ImageModal src={imagesDV.highway3} alt="highway-3" height="200"/>
          <ImageModal src={imagesDV.horizon1} alt="horizon-1" height="200"/>
          <ImageModal src={imagesDV.horizon2} alt="horizon-2" height="200"/>
          <ImageModal src={imagesDV.spanishtrail1} alt="spanishtrail-1" height="200"/>
          <ImageModal src={imagesDV.spanishtrail2} alt="spanishtrail-2" height="200"/>
          <ImageModal src={imagesDV.spanishtrail3} alt="spanishtrail-3" height="200"/>
          <ImageModal src={imagesDV.spanishtrail4} alt="spanishtrail-4" height="200"/>
          <ImageModal src={imagesDV.temp1} alt="temp-1" height="200"/>
          <ImageModal src={imagesDV.trail1} alt="trail-1" height="200"/>
          <ImageModal src={imagesDV.zabriskie1} alt="zabriskie-1" height="200"/>
          <ImageModal src={imagesDV.zabriskie2} alt="zabriskie-2" height="200"/>
          <ImageModal src={imagesDV.zabriskie3} alt="zabriskie-3" height="200"/>
          <ImageModal src={imagesDV.zabriskie4} alt="zabriskie-4" height="200"/>
          <ImageModal src={imagesDV.zabriskie5} alt="zabriskie-5" height="200"/>
          <ImageModal src={imagesDV.zabriskie6} alt="zabriskie-6" height="200"/>
          <ImageModal src={imagesDV.zabriskie7} alt="zabriskie-7" height="200"/>
          <ImageModal src={imagesDV.zabriskie8} alt="zabriskie-8" height="200"/>
          <ImageModal src={imagesDV.zabriskie9} alt="zabriskie-9" height="200"/>
          <ImageModal src={imagesDV.zabriskie10} alt="zabriskie-10" height="200"/>
        </div>
      }
    </>
  )
}
export default DeathValley