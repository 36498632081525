import { imagesUS } from '../../components/imagesUS'
import Image from '../../components/Image'
import { Link } from "react-router-dom"

function US() {
  return (
    <>
      {!imagesUS && <div>Loading...</div>}
      {imagesUS && 
        <div className='images imagesHome'>      
          <div className="linkImage">
            <Link to='/everglades'>
              <Image src={imagesUS.gator20} alt="everglades" height="200"/>
              <p className="imageLabel">Everglades</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/miami'>
              <Image src={imagesUS.artdeco2} alt="miami" height="200"/>
              <p className="imageLabel">Miami</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/dc'>
              <Image src={imagesUS.memorial2} alt="dc" height="200"/>
              <p className="imageLabel">Washington DC</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/philly'>
              <Image src={imagesUS.phillyskyline2} alt="philly" height="200"/>
              <p className="imageLabel">Philadelphia</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/nyc'>
              <Image src={imagesUS.chrysler4} alt="nyc" height="200"/>
              <p className="imageLabel">New York</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/smoky'>
              <Image src={imagesUS.mountains2} alt="smoky" height="200"/>
              <p className="imageLabel">Smoky Mountains</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/vegas'>
              <Image src={imagesUS.strat2} alt="vegas" height="200"/>
              <p className="imageLabel"> Las Vegas</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/hoover'>
              <Image src={imagesUS.fulldam3} alt="hoover" height="200"/>
              <p className="imageLabel">Hoover Dam</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/antelope'>
              <Image src={imagesUS.bwantelope5} alt="antelope" height="200"/>
              <p className="imageLabel">Antelope Canyon</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/horseshoe'>
              <Image src={imagesUS.horseshoe2} alt="horseshoe" height="200"/>
              <p className="imageLabel">Horseshoe Bend</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/yosemite'>
              <Image src={imagesUS.halfdome5} alt="yosemite" height="200"/>
              <p className="imageLabel">Yosemite</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/zion'>
              <Image src={imagesUS.mountains7} alt="zion" height="200"/>
              <p className="imageLabel">Zion Canyon</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/sr1'>
              <Image src={imagesUS.coast10} alt="sr1" height="200"/>
              <p className="imageLabel">State Route 1</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/grandcanyon'>
              <Image src={imagesUS.tree3} alt="grandcanyon" height="200"/>
              <p className="imageLabel">Grand Canyon</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/bryce'>
              <Image src={imagesUS.hoodoo3} alt="bryce" height="200"/>
              <p className="imageLabel">Bryce Canyon</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/moab'>
              <Image src={imagesUS.arch3} alt="moab" height="200"/>
              <p className="imageLabel">Arches & Canyonlands</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/deathvalley'>
              <Image src={imagesUS.zabriskie5} alt="deathvalley" height="200"/>
              <p className="imageLabel">Death Valley</p>
            </Link>
          </div>
        </div>
      }
    </>
  )
}
export default US