import { imagesMalta } from '../../components/Europe/imagesMalta'
import ImageModal from '../../components/ImageModal'

function Malta() {
  return (
    <>
      {!imagesMalta && <div>Loading...</div>}
      {imagesMalta && 
        <div className="images">
          <ImageModal src={imagesMalta.aerial1} alt="aerial-1" height="200"/>
          <ImageModal src={imagesMalta.alley1} alt="alley-1" height="200"/>
          <ImageModal src={imagesMalta.arch1} alt="arch-1" height="200"/>
          <ImageModal src={imagesMalta.balcony1} alt="balcony-1" height="200"/>
          <ImageModal src={imagesMalta.bell1} alt="bell-1" height="200"/>
          <ImageModal src={imagesMalta.crest1} alt="crest-1" height="200"/>
          <ImageModal src={imagesMalta.crest2} alt="crest-2" height="200"/>
          <ImageModal src={imagesMalta.dive1} alt="dive-1" height="200"/>
          <ImageModal src={imagesMalta.dive2} alt="dive-2" height="200"/>
          <ImageModal src={imagesMalta.dive3} alt="dive-3" height="200"/>
          <ImageModal src={imagesMalta.dive4} alt="dive-4" height="200"/>
          <ImageModal src={imagesMalta.fireworks1} alt="fireworks-1" height="200"/>
          <ImageModal src={imagesMalta.flower1} alt="flower-1" height="200"/>
          <ImageModal src={imagesMalta.harbour1} alt="harbour-1" height="200"/>
          <ImageModal src={imagesMalta.harbour2} alt="harbour-2" height="200"/>
          <ImageModal src={imagesMalta.harbour3} alt="harbour-3" height="200"/>
          <ImageModal src={imagesMalta.harbour4} alt="harbour-4" height="200"/>
          <ImageModal src={imagesMalta.harbour5} alt="harbour-5" height="200"/>
          <ImageModal src={imagesMalta.illuminations1} alt="illuminations-1" height="200"/>
          <ImageModal src={imagesMalta.knight1} alt="knight-1" height="200"/>
          <ImageModal src={imagesMalta.lion1} alt="lion-1" height="200"/>
          <ImageModal src={imagesMalta.lion2} alt="lion-2" height="200"/>
          <ImageModal src={imagesMalta.ocean1} alt="ocean-1" height="200"/>
          <ImageModal src={imagesMalta.statue1} alt="statue-1" height="200"/>
          <ImageModal src={imagesMalta.sunrise1} alt="sunrise-1" height="200"/>
          <ImageModal src={imagesMalta.sunset1} alt="sunset-1" height="200"/>
          <ImageModal src={imagesMalta.tower1} alt="tower-1" height="200"/>
          <ImageModal src={imagesMalta.trails1} alt="trails-1" height="200"/>
          <ImageModal src={imagesMalta.window1} alt="window-1" height="200"/>
        </div>
      }
    </>
  )
}
export default Malta