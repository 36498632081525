import { imagesBarcelona } from '../../components/Europe/imagesBarcelona'
import ImageModal from '../../components/ImageModal'

function Barcelona() {
  return (
    <>
      {!imagesBarcelona && <div>Loading...</div>}
      {imagesBarcelona &&       
        <div className="images">
          <ImageModal src={imagesBarcelona.aerial1} alt="aerial-1" height="200"/>
          <ImageModal src={imagesBarcelona.aerial2} alt="aerial-2" height="200"/>
          <ImageModal src={imagesBarcelona.candles1} alt="candles-1" height="200"/>
          <ImageModal src={imagesBarcelona.cat1} alt="cat-1" height="200"/>
          <ImageModal src={imagesBarcelona.cat2} alt="cat-2" height="200"/>
          <ImageModal src={imagesBarcelona.cat3} alt="cat-3" height="200"/>
          <ImageModal src={imagesBarcelona.cat4} alt="cat-4" height="200"/>
          <ImageModal src={imagesBarcelona.church1} alt="church-1" height="200"/>
          <ImageModal src={imagesBarcelona.door1} alt="door-1" height="200"/>
          <ImageModal src={imagesBarcelona.familia1} alt="familia-1" height="200"/>
          <ImageModal src={imagesBarcelona.familia2} alt="familia-2" height="200"/>
          <ImageModal src={imagesBarcelona.flower1} alt="flower-1" height="200"/>
          <ImageModal src={imagesBarcelona.fog1} alt="fog-1" height="200"/>
          <ImageModal src={imagesBarcelona.gate1} alt="gate-1" height="200"/>
          <ImageModal src={imagesBarcelona.gate2} alt="gate-2" height="200"/>
          <ImageModal src={imagesBarcelona.horizon1} alt="horizon-1" height="200"/>
          <ImageModal src={imagesBarcelona.horizon2} alt="horizon-2" height="200"/>
          <ImageModal src={imagesBarcelona.path1} alt="path-1" height="200"/>
          <ImageModal src={imagesBarcelona.path2} alt="path-2" height="200"/>
          <ImageModal src={imagesBarcelona.sculpture1} alt="sculpture-1" height="200"/>
          <ImageModal src={imagesBarcelona.sun1} alt="sun-1" height="200"/>
          <ImageModal src={imagesBarcelona.tower1} alt="tower-1" height="200"/>
          <ImageModal src={imagesBarcelona.towers1} alt="towers-1" height="200"/>
          <ImageModal src={imagesBarcelona.towers2} alt="towers-2" height="200"/>
          <ImageModal src={imagesBarcelona.tunnel1} alt="tunnel-1" height="200"/>
          <ImageModal src={imagesBarcelona.turtle1} alt="turtle-1" height="200"/>
          <ImageModal src={imagesBarcelona.waterfall1} alt="waterfall-1" height="200"/>
        </div>
      }
    </>
  )
}
export default Barcelona