import { imagesHoover } from '../../../components/US/West/imagesHoover'
import ImageModal from '../../../components/ImageModal'

function Hoover() {
  return (
    <>
      {!imagesHoover && <div>Loading...</div>}
      {imagesHoover && 
        <div className="images">
          <ImageModal src={imagesHoover.aerialdam1} alt="aerialdam-1" height="200"/>
          <ImageModal src={imagesHoover.bridge1} alt="bridge-1" height="200"/>
          <ImageModal src={imagesHoover.camaro1} alt="camaro-1" height="200"/>
          <ImageModal src={imagesHoover.dam1} alt="dam-1" height="200"/>
          <ImageModal src={imagesHoover.dam2} alt="dam-2" height="200"/>
          <ImageModal src={imagesHoover.damout1} alt="damout-1" height="200"/>
          <ImageModal src={imagesHoover.damout2} alt="damout-2" height="200"/>
          <ImageModal src={imagesHoover.fisheyedam1} alt="fisheyedam-1" height="200"/>
          <ImageModal src={imagesHoover.fisheyedamout1} alt="fisheyedamout-1" height="200"/>
          <ImageModal src={imagesHoover.fulldam1} alt="fulldam-1" height="200"/>
          <ImageModal src={imagesHoover.fulldam2} alt="fulldam-2" height="200"/>
          <ImageModal src={imagesHoover.fulldam3} alt="fulldam-3" height="200"/>
          <ImageModal src={imagesHoover.fulldam4} alt="fulldam-4" height="200"/>
          <ImageModal src={imagesHoover.hills1} alt="hills-1" height="200"/>
          <ImageModal src={imagesHoover.lakemead1} alt="lakemead-1" height="200"/>
          <ImageModal src={imagesHoover.lakemead2} alt="lakemead-2" height="200"/>
          <ImageModal src={imagesHoover.lakemead3} alt="lakemead-3" height="200"/>
          <ImageModal src={imagesHoover.lakemead4} alt="lakemead-4" height="200"/>
          <ImageModal src={imagesHoover.towers1} alt="towers-1" height="200"/>
          <ImageModal src={imagesHoover.towers2} alt="towers-2" height="200"/>
        </div>
      }
    </>
  )
}
export default Hoover