import { imagesNYC } from '../../../components/US/NorthEast/imagesNYC'
import ImageModal from '../../../components/ImageModal'

function NYC() {
  return (
    <>
      {!imagesNYC && <div>Loading...</div>}
      {imagesNYC &&       
        <div className="images">
          <ImageModal src={imagesNYC.chrysler1} alt="chrysler-1" height="200"/>
          <ImageModal src={imagesNYC.chrysler2} alt="chrysler-2" height="200"/>
          <ImageModal src={imagesNYC.chrysler3} alt="chrysler-3" height="200"/>
          <ImageModal src={imagesNYC.chrysler4} alt="chrysler-4" height="200"/>
          <ImageModal src={imagesNYC.chrysler5} alt="chrysler-5" height="200"/>
          <ImageModal src={imagesNYC.chrysler6} alt="chrysler-6" height="200"/>
          <ImageModal src={imagesNYC.chrysler7} alt="chrysler-7" height="200"/>
          <ImageModal src={imagesNYC.empire1} alt="empire-1" height="200"/>
          <ImageModal src={imagesNYC.empire2} alt="empire-2" height="200"/>
          <ImageModal src={imagesNYC.empire3} alt="empire-3" height="200"/>
          <ImageModal src={imagesNYC.empire4} alt="empire-4" height="200"/>
          <ImageModal src={imagesNYC.empire5} alt="empire-5" height="200"/>
          <ImageModal src={imagesNYC.empire6} alt="empire-6" height="200"/>
          <ImageModal src={imagesNYC.flatiron1} alt="flatiron-1" height="200"/>
          <ImageModal src={imagesNYC.liberty1} alt="liberty-1" height="200"/>
          <ImageModal src={imagesNYC.madison1} alt="madison-1" height="200"/>
          <ImageModal src={imagesNYC.pigeon1} alt="pigeon-1" height="200"/>
          <ImageModal src={imagesNYC.skyline1} alt="skyline-1" height="200"/>
          <ImageModal src={imagesNYC.skyline2} alt="skyline-2" height="200"/>
          <ImageModal src={imagesNYC.skyline3} alt="skyline-3" height="200"/>
          <ImageModal src={imagesNYC.skyline4} alt="skyline-4" height="200"/>
          <ImageModal src={imagesNYC.skyline5} alt="skyline-5" height="200"/>
          <ImageModal src={imagesNYC.skyline6} alt="skyline-6" height="200"/>
          <ImageModal src={imagesNYC.skyline7} alt="skyline-7" height="200"/>
          <ImageModal src={imagesNYC.skyline8} alt="skyline-8" height="200"/>
          <ImageModal src={imagesNYC.skyline9} alt="skyline-9" height="200"/>
          <ImageModal src={imagesNYC.skyline10} alt="skyline-10" height="200"/>
          <ImageModal src={imagesNYC.skyline11} alt="skyline-11" height="200"/>
          <ImageModal src={imagesNYC.skyline12} alt="skyline-12" height="200"/>
          <ImageModal src={imagesNYC.skyline13} alt="skyline-13" height="200"/>
          <ImageModal src={imagesNYC.skyline14} alt="skyline-14" height="200"/>
          <ImageModal src={imagesNYC.skyline15} alt="skyline-15" height="200"/>
          <ImageModal src={imagesNYC.skyline16} alt="skyline-16" height="200"/>
          <ImageModal src={imagesNYC.skyline17} alt="skyline-17" height="200"/>
          <ImageModal src={imagesNYC.skyline18} alt="skyline-18" height="200"/>
          <ImageModal src={imagesNYC.skyline19} alt="skyline-19" height="200"/>
          <ImageModal src={imagesNYC.skyline20} alt="skyline-20" height="200"/>
          <ImageModal src={imagesNYC.skyline21} alt="skyline-21" height="200"/>
          <ImageModal src={imagesNYC.tower1} alt="tower-1" height="200"/>
          <ImageModal src={imagesNYC.tower2} alt="tower-2" height="200"/>
          <ImageModal src={imagesNYC.trump1} alt="trump-1" height="200"/>
          <ImageModal src={imagesNYC.unitednations1} alt="unitednations-1" height="200"/>
          <ImageModal src={imagesNYC.unitednations2} alt="unitednations-2" height="200"/>
        </div>
      }
    </>
  )
}
export default NYC