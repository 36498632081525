import { imagesEurope } from '../../components/imagesEurope'
import Image from '../../components/Image'
import { Link } from "react-router-dom"

function Europe() {
  return (
    <>
      {!imagesEurope && <div>Loading...</div>}
      {imagesEurope && 
        <div className='images imagesHome'>
          <div className="linkImage">
            <Link to='/rome'>
              <Image src={imagesEurope.statue1} alt="rome" height="200"/>
              <p className="imageLabel">Rome</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/malta'>
              <Image src={imagesEurope.lion2} alt="malta" height="200"/>
              <p className="imageLabel">Malta</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/barcelona'>
              <Image src={imagesEurope.door1} alt="barcelona" height="200"/>
              <p className="imageLabel">Barcelona</p>
            </Link>
          </div>
          <div className="linkImage">
            <Link to='/bruges'>
              <Image src={imagesEurope.faces1} alt="bruges" height="200"/>
              <p className="imageLabel">Bruges</p>
            </Link>
          </div>
        </div>
      }
    </>
  )
}
export default Europe