import { imagesYosemite } from '../../../components/US/West/imagesYosemite'
import ImageModal from '../../../components/ImageModal'

function Yosemite() {
  return (
    <>
      {!imagesYosemite && <div>Loading...</div>}
      {imagesYosemite && 
        <div className="images">
          <ImageModal src={imagesYosemite.elcapitan1} alt="elcapitan-1" height="200"/>
          <ImageModal src={imagesYosemite.elcapitan2} alt="elcapitan-2" height="200"/>
          <ImageModal src={imagesYosemite.halfdome1} alt="halfdome-1" height="200"/>
          <ImageModal src={imagesYosemite.halfdome2} alt="halfdome-2" height="200"/>
          <ImageModal src={imagesYosemite.halfdome3} alt="halfdome-3" height="200"/>
          <ImageModal src={imagesYosemite.halfdome4} alt="halfdome-4" height="200"/>
          <ImageModal src={imagesYosemite.halfdome5} alt="halfdome-5" height="200"/>
          <ImageModal src={imagesYosemite.halfdome6} alt="halfdome-6" height="200"/>
          <ImageModal src={imagesYosemite.halfdome7} alt="halfdome-7" height="200"/>
          <ImageModal src={imagesYosemite.halfdome8} alt="halfdome-8" height="200"/>
          <ImageModal src={imagesYosemite.camaro1} alt="camaro-1" height="200"/>
          <ImageModal src={imagesYosemite.mountains1} alt="mountains-1" height="200"/>
          <ImageModal src={imagesYosemite.snowpeak1} alt="snowpeak-1" height="200"/>
          <ImageModal src={imagesYosemite.snowpeak2} alt="snowpeak-2" height="200"/>
          <ImageModal src={imagesYosemite.camaro2} alt="camaro-2" height="200"/>
          <ImageModal src={imagesYosemite.valley1} alt="valley-1" height="200"/>
          <ImageModal src={imagesYosemite.waterfall1} alt="waterfall-1" height="200"/>
          <ImageModal src={imagesYosemite.waterfall2} alt="waterfall-2" height="200"/>
          <ImageModal src={imagesYosemite.waterfall3} alt="waterfall-3" height="200"/>
          <ImageModal src={imagesYosemite.waterfall4} alt="waterfall-4" height="200"/>
        </div>
      }
    </>
  )
}
export default Yosemite