import { imagesHorseshoe } from '../../../components/US/West/imagesHorseshoe'
import ImageModal from '../../../components/ImageModal'

function Horseshoe() {
  return (
    <>
      {!imagesHorseshoe && <div>Loading...</div>}
      {imagesHorseshoe && 
        <div className="images">
          <ImageModal src={imagesHorseshoe.bwfisheyehorseshoe1} alt="bwfisheyehorseshoe-1" height="200"/>
          <ImageModal src={imagesHorseshoe.bwhorseshoe1} alt="bwhorseshoe-1" height="200"/>
          <ImageModal src={imagesHorseshoe.horseshoe1} alt="horseshoe-1" height="200"/>
          <ImageModal src={imagesHorseshoe.horseshoe2} alt="horseshoe-2" height="200"/>
          <ImageModal src={imagesHorseshoe.sky1} alt="sky-1" height="200"/>
        </div>
      }
    </>
  )
}
export default Horseshoe