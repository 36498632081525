import statue1 from '../../public/static/img/Europe/rome/statue1.jpg'
import lion2 from '../../public/static/img/Europe/malta/lion2.jpg'
import faces1 from '../../public/static/img/Europe/bruges/faces1.jpg'
import door1 from '../../public/static/img/Europe/barcelona/door1.jpg'

export const imagesEurope = {
  statue1,
  lion2,
  faces1,
  door1
}